<template>
  <div class="modal-content">
    <div class="modal-header">
      <svg v-svg @click="$emit('closeModal')" class="close mr-2 mt-2" symbol="modal-close" size="0 0 15 15" role="info"></svg>
    </div>
    <div class="modal-body p-5">
      <h2>ID: {{ creative.id }}</h2>
      <div class="push-container p-3" v-if="creative.format === 'Web Push'">
        <div class="head">
          <div>
            <svg v-svg symbol="chrome" size="0 0 15 15" role="info"></svg>
            <small class="pl-1">Chrome • mysite.com • now </small>
          </div>
          <span class="d-block">{{ creative.adTitle }}</span>
          <span class="d-block">{{ creative.adText }}</span>
          <img :src="creative.adIcon" class="ad-icon" />
        </div>
        <img :src="creative.adImage" class="ad-image" />
        <span class="ad-btn">SETTINGS</span>
        <span class="ad-btn">MORE</span>
      </div>
    
      <div class="inpage-container text-center" v-if="creative.format === 'In-Page'">
        <img :src="require(`@/assets/images/previews/noAd/${previewImage}`)" v-if="isImage">
        <LottieAnimation v-else ref="anim" :loop="true" :auto-play="true" :animationData="require(`@/assets/images/previews/noAd/${previewImage}`)" class="preview" />
      </div>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation
  },
  props: {
    creative: {
      type: Object,
    },
  },
  computed:{
     isImage() {
        return !this.previewImage.endsWith(".json");
      },
      previewImage() {
      const animation = ["Twist Notification", "Spin Animation", "Ripple effect", "Ripple effect 2", "Purple gradient", "Pure white", "Float Motion"];
      
      console.log(`animate/${this.imageMap[this.creative.template]}.json`);
      
      if (!this.creative.template) return 'Main.svg'

      return animation.includes(this.creative.template) ? `animate/${this.imageMap[this.creative.template]}.json` : `${this.imageMap[this.creative.template]}.svg`;
       
    },
    imageMap() {
      return {
        Default: "Main",
        Mac: "Mac",
        Windows: "Windows",
        Android: "Android",
        iOS: "iOS",
        Black: "Black",
        White: "White",
        "Pink(Dating)": "Pink_Dating",
        "White(Online)": "White_Online",
        Facebook: "Facebook",
        Instagram: "Instagram",
        Telegram: "Telegram",
        Twitter: "Twitter",
        Whatsapp: "Whatsapp",
        Small: "Small",
        Dialog: "Dialog",
        Compact: "Compact",
        "Static display": "Static_display",
        Light: "Light",
        Dark: "Dark",
        "IOS Dark": "iOS_Dark",
        Large: "Large",
        "Large 1": "Large_1",
        "Large 2": "Large_2",
        "Large 3": "Large_3",
        Deep: "Deep",
        Frame: "Frame",
        Grand: "Grand",
        "Twist Notification": "Twist_Notification",
        "Spin Animation": "Spin_animation",
        "Ripple effect": "Ripple_effect",
        "Ripple effect 2": "Ripple_effect_2",
        "Purple gradient": "Purple_gradient",
        "Pure white": "Pure_white",
        "Float Motion": "Float_Motion",
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.dark .push-container {
  span,small {
  color: #000;
  }
}
.push-container {
  position: relative;
  border-radius: 8px;
  background: #fff;
  max-width: 350px;
  margin: 0 auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06);

  .head {
    display: flex;
    flex-flow: column wrap;
    img.ad-icon {
      position: absolute;
      right: 16px;
      height: 32px;
      width: 32px;
    }
    span {
      font-size: 13px;
      color: #3c3c3c;
      &:nth-child(2) {
         font-size: 14px;
         color: #000000;
      }
    }
  }
  img.ad-image {
    width: 100%;
    height: 200px;
  }
  .ad-btn {
    padding-top: 10px;
    padding-right: 18px;
    color: #2891e7 !important;
    display: inline-block;
  }
}
</style>
