<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12">
        <div class="row align-items-center">
          <div class="d-flex align-items-center col-12 col-lg-4 col-md-4">
            <button class="back mr-2" @click="$router.back()">
              <svg v-svg role="presentation" size="0 0 20 14" symbol="arrow-back" />
            </button>
            <h1 class="mb-0 pt-0">{{ $t("active_camp.title") }}</h1>
          </div>
          <div class="col-12 col-lg-8 col-md-8 ml-auto">
            <div class="row align-items-center">
              <div class="col-12 col-md-4 mt-3 mt-md-0 search-field">
                <input v-model="search" type="search" @keydown.enter="filterCampaigns" @blur="filterCampaigns" :placeholder="$t('search_placeholder')" />
                <button @click="filterCampaigns" class="icon-search" title="Search">
                  <svg v-svg symbol="search" size="0 0 15 16"></svg>
                </button>
              </div>

              <custom-multiselect class="col-12 col-md-4 mt-3 mt-md-0" v-model="option" :list="campaignOptions" :allowEmpty="false">
                <template v-slot:block="props">
                  <span>
                    {{ props.value.value ? formatLabel(props.value.title) + totalLocked : formatLabel(props.value.title) + totalUnLocked }}
                  </span>
                </template>
                <template v-slot:list="props">
                  <span>
                    {{ props.value.value ? formatLabel(props.value.title) + totalLocked : formatLabel(props.value.title) + totalUnLocked }}
                  </span>
                </template>
              </custom-multiselect>
              <div class="col-12 col-md-4 mt-3 mt-md-0">
                <button :disabled="rows && rows.length == 0" @click="$modal.show('showUnlockAll')" class="px-3 btn _bg-green">{{ $t("active_camp.unlock_all") }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sep _h20"></div>
    <div class="c-table">
      <div class="inner">
        <vue-good-table styleClass="table _no-wrap" :columns="columns" :rows="rows">
          <template slot="table-column" slot-scope="props">
            <span>
              {{ props.column.label ? formatLabel(props.column.label) + ":" : "" }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <template v-if="props.column.field === 'id'">
              <span>
                {{ props.row.id || "-" }}
              </span>
            </template>
            <template v-if="props.column.field === 'format'">
              <span class="format-label">
                {{ props.row.format }}
              </span>
            </template>
            <template v-if="props.column.field === 'watch'">
              <svg v-svg v-if="props.row.format !== 'On-Click'" @click="showCreative(props.row)" symbol="eye-green" size="0 0 15 15" class="watch-btn" />
              <svg v-svg v-else symbol="open" size="0 0 15 15" @click="openLink(props.row.url)" class="watch-btn" />
            </template>
            <template v-if="props.column.field === 'ad'">
              <div class="d-flex" v-if="props.row.format !== 'On-Click'">
                <img v-if="props.row.format === 'Web Push'" :src="props.row.adImage" class="ad-image" />
                <img v-if="props.row.format !== 'On-Click'" :src="props.row.adIcon" class="ad-icon" />
                <div class="description d-grid" v-if="props.row.format !== 'On-Click'">
                  <span>
                    {{ props.row.adTitle }}
                  </span>
                  <small> {{ props.row.adText }}</small>
                </div>
              </div>
              <span class="campaign-link" v-else>
                <svg v-svg role="presentation" size="0 0 18 16" symbol="link"></svg>
                {{ props.row.url }}</span
              >
            </template>
            <template v-if="props.column.field === 'action'">
              <button class="ml-auto d-block btn btn-action" @click="toggleLock(props.row.id)" :class="{ locked: option.value === 1 }">
                <svg v-svg v-show="!option.value" symbol="lock" size="0 0 15 15" role="presentation" />
                <svg v-svg v-show="option.value" symbol="lock-open" size="0 0 15 15" role="presentation" />
                {{ option.value ? $t("active_camp.unlock") : $t("active_camp.lock") }}
              </button>
            </template>
          </template>
          <div class="d-flex justify-content-center" slot="emptystate">
            <template v-if="isLoading">
              <div class="d-flex justify-content-center">
                <img :src="require('@/assets/images/rolling.svg')" width="50" alt="loader" class="loader" />
              </div>
            </template>
            <h4 v-else>{{ $t("any_results") }}</h4>
          </div>
        </vue-good-table>
      </div>
    </div>

    <div class="c-pagination mb-5">
      <ul class="pagination" v-if="rows && rows.length && rows.length > 0">
        <li class="page-item">
          <button @click.prevent="page--" :class="{ disabled: page <= 1 || isLoading }" :disabled="isLoading || page <= 1" class="page-link icon-prev prev" data-ci-pagination-page="2" rel="prev" :title="$t('statistic.prev')">
            <svg v-svg symbol="prev" size="0 0 8 14" />
          </button>
        </li>
        <li class="page-item">
          <button @click.prevent="page++" :class="{ disabled: page * limit >= total || isLoading }" :disabled="isLoading || page * limit >= total" class="page-link icon-next next" data-ci-pagination-page="2" rel="next" :title="$t('statistic.next')">
            <svg v-svg symbol="next" size="0 0 8 14" />
          </button>
        </li>
        <li class="page-item">{{ page === 1 ? page : limit * (page - 1) + 1 }} - {{ limit * (page - 1) + rows.length }} {{ $t("from") }} {{ total }}</li>
        <li>
          <custom-multiselect :disabled="isLoading" class="ml-3" v-model="limit" :list="optionsPagination" :allowEmpty="false">
            <template v-slot:block="props">
              <span>
                {{ props.value }}
              </span>
            </template>
            <template v-slot:list="props">
              <span>
                {{ props.value }}
              </span>
            </template>
          </custom-multiselect>
        </li>
      </ul>
    </div>

    <modal @closed="$modal.hide('showCreative')" :width="550" name="showCreative" height="auto" :scrollable="true" classes="creative-modal">
      <Creative :creative="currentCreative" @closeModal="$modal.hide('showCreative')" />
    </modal>
    <modal @closed="$modal.hide('showUnlockAll')" :width="550" name="showUnlockAll" height="auto" :scrollable="true" classes="creative-modal">
      <div class="pt-3 pb-4 px-3 mx-auto d-block text-center">
        <svg v-svg @click="$modal.hide('showUnlockAll')" class="close" symbol="modal-close" size="0 0 15 15" role="info"></svg>
        <svg v-svg symbol="lock2" v-if="!isDarkTheme" size="0 0 50 50"></svg>
        <h3 class="modal-title pt-3" v-html="$t('active_camp.unlock_confirm')"></h3>
        <button class="btn mt-5 mr-3 _bg-gray" @click="$modal.hide('showUnlockAll')">{{ $t("cancel") }}</button>
        <button class="btn mt-5 _bg-green" @click="unlockAll">{{ $t("active_camp.unlock") }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import "@/assets/css/_pagination.scss";

import serviceDomain from "@/api/serviceDomain";
import Creative from "@/components/modal/Creative.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Creative,
  },
  data: () => ({
    limit: 10,
    optionsPagination: [10, 25, 50, 100],
    page: 1,
    offset: 0,
    total: null,
    unlocked_total: 0,
    locked_total: 0,
    search: "",
    isLoading: true,
    currentCreative: null,

    rows: [],
    columns: [
      {
        label: "ID",
        field: "id",
      },
      {
        label: "platforms.table.format",
        field: "format",
      },
      {
        field: "watch",
      },
      {
        label: "platforms.table.ad",
        field: "ad",
      },
      {
        field: "action",
      },
    ],
    campaignOptions: [
      { title: "active_camp.locked", value: 1 },
      { title: "active_camp.unlocked", value: 0 },
    ],
    option: { title: "active_camp.locked", value: 1 },
  }),
  computed: {
    ...mapGetters({
      isDarkTheme: "user/isDarkTheme",
    }),
    totalLocked() {
     return " (" + this.locked_total + ")";
    },
    totalUnLocked() {
        return " (" + this.unlocked_total + ")";
      }
  },
  methods: {
    formatLabel(el) {
      return this.$t(el);
    },
    showCreative(data) {
      this.currentCreative = data;
      this.$modal.show("showCreative");
    },
    openLink(url) {
      window.open(url);
    },
    unlockAll() {
      serviceDomain.unlockAllCampaigns(this.$route.params.id).then((res) => {
        if (res && res.status == 200) {
          this.$alert({
            type: "success",
            title: this.$t("sent_success"),
          });
          this.getCurrentCampaign();
        }
      });
      this.$modal.hide("showUnlockAll");
    },
    toggleLock(id) {
  
  const action = this.option.value == 1 ? 'unlock' : 'lock';
  const method = action === 'unlock' 
    ? serviceDomain.unlockCampaign 
    : serviceDomain.lockCampaign;

  method(this.$route.params.id, id)
    .then((res) => {
      if (res && res.status === 200) {
        this.$alert({
          type: "success",
          title: this.$t("sent_success"),
          text: this.$t(`active_camp.${action}ed`),
        });
        this.getCurrentCampaign();
      } else {
        this.$alert({
          type: "error",
          title: this.$t("sent_error"),
        });
      }
    })
    .catch((error) => {
      console.error(`${action}Campaign failed:`, error);
      this.$alert({
        type: "error",
        title: this.$t("sent_error"),
      });
    });
},

    filterCampaigns() {
      this.getCurrentCampaign() 
    },
    getCurrentCampaign() {
     
      const payload = {
        id: this.$route.params?.id,
        locked: this.option.value,
        limit: this.limit,
        offset: this.offset,
        search: this.search.length > 0 ? this.search: null
      };
      this.isLoading = true;

      serviceDomain
        .getCurrentCampaign(payload)
        .then((res) => {
          if (res && res.status === 200 && res.data) {
            this.rows = res.data?.data.rows;
            this.unlocked_total = res.data?.data.unlocked_total || 0;
            this.locked_total = res.data?.data.locked_total || 0;
            this.total = res.data?.data.total;
          } else {
            this.$alert({
              type: "error",
              title: this.$t("sent_error"),
              text: this.res?.message || this.res,
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    page() {
      this.rows = [];
      this.offset = this.limit * (this.page - 1);
    },
    option() {
      this.getCurrentCampaign();
    },
    limit() {
      this.rows = [];
      this.getCurrentCampaign();
    },
    offset() {
      this.getCurrentCampaign();
    },
  },
  mounted() {
    this.getCurrentCampaign();
  },
};
</script>

<style scoped lang="scss">
.description {
  display: grid;
  margin-left: 14px;
  span {
    font-size: 14px;
  }
  small {
    color: rgba(151, 151, 151, 1);
    font-size: 13px;
  }
}

.dark button.back {
  opacity: 0.5;
}

h3.modal-title {
  max-width: 60%;
  text-align: center;
  margin: 0 auto;
  ::v-deep span {
    background: #d6ebeba6;
    border-radius: 10px;
    line-height: 2;
    padding: 6px;
    color: #317475;
  }
}

span.campaign-link {
  svg {
    fill: #317475;
  }
  color: #317475;
  font-weight: 400;
}

svg.watch-btn {
  cursor: pointer;
  border-radius: 4px;
  width: 28px;
  height: 28px;
  padding: 6px;
  fill: #317475;
  border: 1px solid #317475;
}

button.back {
  background: rgba(247, 247, 249, 1);
  border-radius: 10px;
  width: 50px;
  height: 50px;
}

button.btn-action {
  background: transparent;
  border: 1px solid #c62424;
  color: #c62424;
  font-weight: 400;
  svg {
    fill: #c62424;
    vertical-align: baseline;
  }
  &:hover {
    background: #c62424 !important;
  }
  &.locked {
    border: none;
    background: #317475;
    color: #fff;
    svg {
      fill: #fff;
    }
    &:hover {
      background: rgb(36, 92, 93) !important;
    }
  }
}

span.format-label {
  display: inline-block;
  min-width: 60px;
  text-align: center;
  border: 1px solid rgba(228, 228, 234, 1);
  background: #fff;
  color: rgba(20, 20, 20, 1);
  font-size: 12px;
  border-radius: 5px;
  padding: 6px;
}

img.ad-image {
  height: 40px;
  width: auto;
  border-radius: 4px;
}

img.ad-icon {
  height: 40px;
  width: auto;
  margin-left: 8px;
  border-radius: 4px;
}

.title-row h1 {
  vertical-align: middle;
  display: inline-block;
  font-size: 22px;
  @media screen and (min-width: 768px) {
    font-size: 26px;
  }
}

.col-4 {
  padding: 0 10px !important;
}
</style>
